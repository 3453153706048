<template>
  <div>
    <v-card>
      <v-stepper v-model="currentStep" vertical>
        <v-stepper-step :complete="currentStep > 1" step="1">
          {{$t('account_details')}}
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-alert :value="true" type="error" v-if="errorMessage.length > 0">{{ errorMessage }}</v-alert>
          {{$t('provide_account_details')}}
          <v-form ref="step1">
            <v-text-field
              v-model="resetPasswordRequest.email"
              :label= "$t('email')"
              required
              clearable
              :rules="emailRules"
            ></v-text-field>
          <v-autocomplete
            v-model="resetPasswordRequest.country"
            :items="countries"
            color="white"
            item-text="title"
            item-value="title"
           :label= "$t('country')"
           :rules="[value => !!value || `${$t('country')} ${$t('required')}`]"
          ></v-autocomplete>
          </v-form>
          <v-btn color="primary" @click="navigateStep2()" :disabled="disabledStep2">{{$t('continue')}}</v-btn>
        </v-stepper-content>
        <v-stepper-step :complete="currentStep > 2" step="2">
          {{$t('new_password')}}
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-alert :value="true" type="error" v-if="errorMessage.length > 0">{{ errorMessage }}</v-alert>
          <v-alert :value="true" type="success" v-if="successMessage.length > 0">{{ successMessage }}</v-alert>
          {{$t('provide_password')}}
          <v-form ref="step2">
            <v-text-field
              v-model="resetPasswordRequest.newPassword"
              :label= "$t('new_password')"
              required
              clearable
              :rules="passwordRules"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-text-field
              v-model="resetPasswordRequest.confirmNewPassword"
              :label= "$t('confirm_new_password')"
              required
              clearable
              :rules="confirmationPasswordRules"
              :type="showConfirmPassword ? 'text' : 'password'"
              :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
              @click:append="showConfirmPassword = !showConfirmPassword"
            ></v-text-field>
            <v-text-field
              type="number"
              v-model="resetPasswordRequest.otp"
              :label= "$t('otp')"
              clearable
              :rules="otpCodeRules"
            ></v-text-field>
          </v-form>
          <v-btn color="primary" @click="navigateStep3()">{{$t('continue')}}</v-btn>
        </v-stepper-content>
        <v-stepper-step :complete="currentStep > 3" step="3">{{$t('confirmation')}}</v-stepper-step>
        <v-stepper-content
          step="3"
        >{{$t('successful_password_change')}}</v-stepper-content>
      </v-stepper>
    </v-card>

    <!-- <div class="text-right py-4">
      <p>{{ $t('trying_to') }}<span class="font-weight-bold"> {{$t('activate_account')}}</span>? {{$t('click')}} <a @click="$router.push('/activateaccount')">{{$t('here')}}</a>.</p>
    </div> -->
  </div>
</template>

<script>
import { DataService } from "../services/DataService";

const dataService = new DataService();

export default {
  data() {
    return {
      otp: this.$t('otp'),
      emailRules: [
        v => !!v || `${this.$t('email')} ${this.$t('required')}`,
        v => /.+@.+\..+/.test(v) || this.$t('email_valid'),
      ],
      passwordRules: [
        value => !!value || `${this.$t('password')} ${this.$t('required')}`,
        value => (value || '').length >= 8 || this.$t('min_characters', { 1: 8 }),
      ],
      confirmationPasswordRules: [
        value => !!value || this.$t('confirmation_password'),
        value => (value || '').length >= 8 || this.$t('min_characters', { 1: 8 }),
        value => (value === this.resetPasswordRequest.newPassword) || this.$t('confirmation_password_mismatch'),
      ],
      otpCodeRules: [
        value => !!value || `${this.$t('otp_code')} ${this.$t('required')}`,
        value => (value || '').length == 6 || this.$t('six_characters'),
      ],
      disabledStep2: false,
      currentStep: 1,
      errorMessage: "",
      successMessage: "",
      selectedCountry: {},
      showPassword: false,
      showConfirmPassword: false,
      resetPasswordRequest: {},
      valid: true,
      countries: []
    };
  },

  methods: {
    async navigateStep2() {
      this.disabledStep2 = true;

      let v = await this.$refs.step1.validate();
      
      if (v) {
        dataService.submitResetPasswordRequest(this.resetPasswordRequest).then(r => {
          this.errorMessage = ""
          this.successMessage = r.message
          this.currentStep = 2
        }).catch(e => {
          this.errorMessage = this.$t(e.response.data.message)
          this.disabledStep2 = false;
          this.$store.state.showDialogMessage = false
        });
      }
    },

    async navigateStep3() {
      let v = await this.$refs.step2.validate();
      if (v) {
        dataService.resetPassword(this.resetPasswordRequest).then(() => {
          this.errorMessage = ""
          this.currentStep = 3
        }).catch(e => {
          this.errorMessage = e.response.data.message
        });
      }
    },

    async submitToken() {
      let v = await this.$validator.validateAll();
      if (v) {
        dataService.submitRegistrationToken({
          email: this.user.email,
          otp: this.user.otp
        }).then(() => {
          this.step3Visible = false;
          this.step4Visible = true;
        }).catch(() => {
          this.errorMessage = this.$t('otp_not_match');
        });
      }
    }
  },
  mounted() {
    this.resetPasswordRequest.idfa = this.$route.query.idfa;

    dataService.getConfig("countries").then(r => {
      this.countries = r;
    })
  }
};
</script>